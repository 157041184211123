import * as React from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_usaTerritories2Low from "@amcharts/amcharts5-geodata/usaTerritories2Low";

import { heatmapData, rates, reduceTerritoriesToRegions } from "./regions";

import "./styles.scss";
const UsaMap: React.FunctionComponent = () => {
  React.useEffect(() => {
    console.log("map");
    const root = am5.Root.new("map");

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        projection: am5map.geoAlbersUsa(),
        panX: "none",
        panY: "none",
        wheelX: "none",
        wheelY: "none",
        layout: root.horizontalLayout,
        pinchZoom: false,
        homeZoomLevel: 1,
        homeGeoPoint: { longitude: -98, latitude: 39 },
      })
    );

    chart.get("colors")?.set("colors", [
      am5.color("#742F88"),
      am5.color("#a367aa"),
      am5.color("#c08fc0"),
      am5.color("#d9c1dd"), //
      am5.color("#fdfcfd"), //white
      am5.color("#010101"), //black
    ]);

    const usaSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: reduceTerritoriesToRegions(am5geodata_usaTerritories2Low),
        exclude: ["US-VI", "US-GU", "US-MP", "US-AS"],
        valueField: "rate",
        calculateAggregates: true,
      })
    );

    usaSeries.mapPolygons.template.setAll({
      strokeWidth: 0,
      templateField: "polygonSettings",
    });

    usaSeries.set("heatRules", [
      {
        target: usaSeries.mapPolygons.template,
        dataField: "value",
        min: am5.color("#eeb7c5"),
        max: am5.color("#c50f3c"),
        key: "fill",
        // customFunction: function (sprite, min, max, value) {
        //   console.log({ min, max, value });
        //   const bin = value / 5;
        // },
      },
    ]);

    usaSeries.data.setAll(heatmapData);

    usaSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x627d00),
    });

    usaSeries.mapPolygons.template.events.on("click", (ev) => {
      console.log(ev);
    });

    const stateSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, { visible: false })
    );

    stateSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x627d00),
      stroke: am5.color(0x627d00),
      strokeWidth: 0,
    });

    const labels = chart.series.push(
      am5map.MapPointSeries.new(root, {
        polygonIdField: "polygonId",
      })
    );
    labels.bullets.push(() => {
      return am5.Bullet.new(root, {
        sprite: am5.Label.new(root, {
          //fontSize: 10,
          centerX: am5.p50,
          textAlign: "center",
          centerY: am5.p50,
          text: "{name}",
          populateText: true,
        }),
      });
    });

    usaSeries.events.on("datavalidated", (ev) => {
      chart.goHome();
      chart.zoomOut();

      let series = ev.target;
      const labelData = [];
      series.mapPolygons.each((polygon) => {
        const id = polygon?.dataItem?.get("id") as string;
        const found = heatmapData.find((s) => s.id === id);
        let name = "";
        if (found) {
          const abbr = found.label;
          const rate = formatPercent(found.rate);
          name = `${abbr}\n${rate}`;
        }

        labelData.push({
          polygonId: id,
          name: name,
        });
      });
      console.log(labelData);

      labels.data.setAll(labelData);
    });
  }, []);

  return <div id="map" className="usa-map"></div>;
};

function formatPercent(n: number | undefined): string {
  if (!n) {
    return "";
  }

  return Math.round(n * 100) + "%";
}

export default UsaMap;
