export type Region = {
  name: string;
  children: string[];
};
const regions: Region[] = [
  {
    name: "Northeast",
    children: [
      "US-CT",
      "US-ME",
      "US-MA",
      "US-NH",
      "US-NJ",
      "US-NY",
      "US-PA",
      "US-RI",
      "US-VT",
    ],
  },
  {
    name: "Midwest",
    children: [
      "US-IL",
      "US-IN",
      "US-IA",
      "US-KS",
      "US-MI",
      "US-MN",
      "US-MO",
      "US-NE",
      "US-ND",
      "US-OH",
      "US-SD",
      "US-WI",
    ],
  },
  {
    name: "West",
    children: [
      "US-AK",
      "US-AZ",
      "US-CA",
      "US-CO",
      "US-HI",
      "US-ID",
      "US-MT",
      "US-NV",
      "US-NM",
      "US-OR",
      "US-UT",
      "US-WA",
      "US-WY",
    ],
  },
  {
    name: "South",
    children: [
      "US-AL",
      "US-AR",
      "US-DE",
      "US-FL",
      "US-GA",
      "US-KY",
      "US-LA",
      "US-MD",
      "US-MS",
      "US-NC",
      "US-OK",
      "US-SC",
      "US-TN",
      "US-TX",
      "US-VA",
      "US-DC",
      "US-WV",
      "US-PR",
    ],
  },
];
export const heatmapData = [
  {
    label: "CO",
    id: "US-CO",
    rate: 0.25,
    value: 25,
  },
  { label: "ID", id: "US-ID", rate: 0.18, value: 0.18 },
  { label: "SD", id: "US-SD", rate: 0.24, value: 0.24 },
  { label: "NE", id: "US-NE", rate: 0.46, value: 0.46 },
  { label: "KS", id: "US-KS", rate: 0.25 },
  { label: "OK", id: "US-OK", rate: 0.21 },
  { label: "TX", id: "US-TX", rate: 0.5, value: 0.5 },
  { label: "NM", id: "US-NM", rate: 0.23 },
  { label: "AZ", id: "US-AZ", rate: 0.26 },
  { label: "NV", id: "US-NV", rate: 0.43 },
  { label: "UT", id: "US-UT", rate: 0.48 },
  { label: "CA", id: "US-CA", rate: 0.5 },
  { label: "OR", id: "US-OR", rate: 0.25 },

  { id: "US-CT", label: "CT", rate: 0.32 },
  { id: "US-ME", label: "ME", rate: 0.23 },
  { id: "US-MA", label: "MA", rate: 0.41 },
  { id: "US-NH", label: "NH", rate: 0.27 },
  { id: "US-NJ", label: "NJ", rate: 0.29 },
  { id: "US-NY", label: "NY", rate: 0.54 },
  { id: "US-PA", label: "PA", rate: 0.36 },
  { id: "US-RI", label: "RI", rate: 0.39 },
  { id: "US-VT", label: "VT", rate: 0.45 },

  { id: "US-IL", label: "IL", rate: 0.33 },
  { id: "US-IN", label: "IN", rate: 0.19 },
  { id: "US-IA", label: "IA", rate: 0.41 },
  { id: "US-KS", label: "KS", rate: 0.25 },
  { id: "US-MI", label: "MI", rate: 0.2 },
  { id: "US-MN", label: "MN", rate: 0.24 },
  { id: "US-MO", label: "MO", rate: 0.23 },
  { id: "US-NE", label: "NE", rate: 0.46 },
  { id: "US-ND", label: "ND", rate: 0.16 },
  { id: "US-OH", label: "OH", rate: 0.21 },
  { id: "US-SD", label: "SD", rate: 0.24 },
  { id: "US-WI", label: "WI", rate: 0.22 },

  { id: "US-AK", label: "AK", rate: 0.17 },
  { id: "US-AZ", label: "AZ", rate: 0.26 },
  { id: "US-CA", label: "CA", rate: 0.3 },
  { id: "US-CO", label: "CO", rate: 0.25 },
  { id: "US-HI", label: "HI", rate: 0.26 },
  { id: "US-ID", label: "ID", rate: 0.18 },
  { id: "US-MT", label: "MT", rate: 0.17 },
  { id: "US-NV", label: "NV", rate: 0.43 },
  { id: "US-NM", label: "NM", rate: 0.23 },
  { id: "US-OR", label: "OR", rate: 0.24 },
  { id: "US-UT", label: "UT", rate: 0.48 },
  { id: "US-WA", label: "WA", rate: 0.28 },
  { id: "US-WY", label: "WY", rate: 0.16 },

  { id: "US-AL", label: "AL", rate: 0.25 },
  { id: "US-AR", label: "AR", rate: 0.25 },
  { id: "US-DE", label: "DE", rate: 0.15 },
  { id: "US-FL", label: "FL", rate: 0.34 },
  { id: "US-GA", label: "GA", rate: 0.31 },
  { id: "US-KY", label: "KY", rate: 0.17 },
  { id: "US-LA", label: "LA", rate: 0.26 },
  { id: "US-MD", label: "MD", rate: 0.21 },
  { id: "US-MS", label: "MS", rate: 0.35 },
  { id: "US-NC", label: "NC", rate: 0.25 },
  { id: "US-OK", label: "OK", rate: 0.21 },
  { id: "US-SC", label: "SC", rate: 0.28 },
  { id: "US-TN", label: "TN", rate: 0.32 },
  { id: "US-TX", label: "TX", rate: 0.28 },
  { id: "US-VA", label: "VA", rate: 0.2 },
  { id: "US-DC", label: "DC", rate: 0.52 },
  { id: "US-WV", label: "WV", rate: 0.14 },
  { id: "US-PR", label: "PR", rate: 0.06 },
];

function translateMultiPolygon(
  territories: GeoJSON.FeatureCollection,
  id: string,
  offsetLat: number,
  offsetLng: number
): GeoJSON.FeatureCollection {
  const features = territories.features.filter((f) => f.id !== id);
  let feature = territories.features.find((f) => f.id === id);

  if (feature && feature.geometry.type === "MultiPolygon") {
    feature.geometry.coordinates = feature.geometry.coordinates.map((one) =>
      one.map((two) =>
        two.map((three) => [three[0] + offsetLng, three[1] + offsetLat])
      )
    );
  }
  if (feature) {
    features.push(feature);
  }
  return {
    type: territories.type,
    features,
  };
}

function scaleMultiPolygon(
  territories: GeoJSON.FeatureCollection,
  id: string,
  scaleFactor: number
): GeoJSON.FeatureCollection {
  const features = territories.features.filter((f) => f.id !== id);
  let feature = territories.features.find((f) => f.id === id);

  if (feature && feature.geometry.type === "MultiPolygon") {
    let reference: number[] | null = null;
    feature.geometry.coordinates = feature.geometry.coordinates.map((one) =>
      one.map((two) =>
        two.map((three) => {
          //setup reference
          if (reference === null) {
            reference = three;
          }
          //translate to reference
          const translated = [three[0] - reference[0], three[1] - reference[1]];
          //scale
          const scaled = [
            translated[0] * scaleFactor,
            translated[1] * scaleFactor,
          ];
          //invert translation
          return [scaled[0] + reference[0], scaled[1] + reference[1]];
        })
      )
    );
  }
  if (feature) {
    features.push(feature);
  }
  return {
    type: territories.type,
    features,
  };
}

export function reduceTerritoriesToRegions(
  territories: GeoJSON.FeatureCollection
): GeoJSON.FeatureCollection {
  const s = stream(territories)
    .pipe((data) => translateMultiPolygon(data, "US-PR", 7, 16))
    .pipe((data) => scaleMultiPolygon(data, "US-AK", 0.66))
    .pipe((data) => translateMultiPolygon(data, "US-AK", 8, -7))
    .pipe((data) => translateMultiPolygon(data, "US-HI", 5, -5));
  const territoriesModified = s.data;
  return territoriesModified;

  //const territoriesWithPrMoved = translateMultiPolygon(territories);
  /*const merged: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: regions.map((r) => {
      const children = territoriesModified.features.filter(
        (f) => typeof f.id === "string" && r.children.includes(f.id)
      );
      const coordinates = children.flatMap((s) => {
        if (s.geometry.type === "MultiPolygon") {
          return s.geometry.coordinates;
        } else if (s.geometry.type === "Polygon") {
          return [s.geometry.coordinates];
        }
      });
      console.log(r.name, children);
      return {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates,
        } as GeoJSON.Geometry,
        properties: {
          name: r.name,
          id: `US-${r.name}`,
          CNTRY: "United States of America",
          TYPE: "Region",
        },
        id: `US-${r.name}`,
      };
    }),
  };

  return merged;*/
}

type Stream<T> = {
  data: T;
  pipe: (transform: (before: T) => T) => Stream<T>;
};
function stream<T>(data: T) {
  function pipe(transform: (before: T) => T): Stream<T> {
    data = transform(data);
    return { data, pipe };
  }
  return { data, pipe };
}
